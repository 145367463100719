import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Helmet } from "react-helmet";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import CandidateBenefitsSection from "./Sections/CandidateBenefitsSection.js";
import BusinessBenefitsSection from "./Sections/BusinessBenefitsSection";

import { Redirect } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [redirectToJobs, setRedirectToJobs] = React.useState(false);
  const [redirectToNewJob, setRedirectToNewJob] = React.useState(false);

  const renderRedirect = () => {
    if (redirectToJobs) {
      return <Redirect to="/jobs" />;
    }
    if (redirectToNewJob) {
      return <Redirect to="/new-job" />;
    }
  };

  return (
    <div>
      {renderRedirect()}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Humboldt Jobs | Find A New Job in Humboldt County</title>
        <link rel="canonical" href="https://www.humboldtjobs.com/" />
      </Helmet>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Let's Talk Jobs</h1>
              <h4>
                Streamlining the Humboldt County Job search for applicants and
                businesses alike by delivering a clean, searchable, and
                interactive job searching experience.
              </h4>
              <br />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon />}
                size="large"
                style={{
                  "&:hover": {
                    color: "white"
                  }
                }}
                onClick={() => setRedirectToJobs(true)}
              >
                Search Jobs
              </Button>
              <Button
                variant="contained"
                color="default"
                startIcon={<LibraryAddIcon />}
                size="large"
                style={{
                  marginLeft: "7px",
                  paddingLeft: "18px",
                  paddingRight: "18px"
                }}
                onClick={() => setRedirectToNewJob(true)}
              >
                <div>Post A Job</div>
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <CandidateBenefitsSection />
          <BusinessBenefitsSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
