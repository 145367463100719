import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles(styles);

export default function AccountPage(props) {
  const classes = useStyles();
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  const { ...rest } = props;

  console.log(user);
  return (
    <div>
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/jobs-bg.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <h2 style={{ padding: "10px" }}>{user.name}</h2>
            </GridContainer>
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <GridItem>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Contact Information
                    </Typography>
                    <p>
                      This is the contact information companies will reach out
                      to you at.
                    </p>
                    <List>
                      <ListItem button>
                        <ListItemText primary="Email" secondary={user.email} />
                      </ListItem>
                      <ListItem button>
                        <ListItemText
                          primary="Phone Number"
                          secondary="(707) 502-0052"
                        />
                      </ListItem>
                      <ListItem button>
                        <ListItemText primary="City" secondary="Eureka" />
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button size="small">Edit</Button>
                  </CardActions>
                </Card>
              </GridItem>

              <GridItem>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Resume
                    </Typography>
                    <List>
                      <ListItem button>
                        <ListItemText
                          primary="connorcallison.pdf"
                          secondary="Uploaded 12/2/2019"
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions>
                    <Button size="small">Replace</Button>
                  </CardActions>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
