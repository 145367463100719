import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import JobsPage from "views/JobsPage/JobsPage.js";
import JobDetail from "views/JobDetail/JobDetail.js";
import AccountPage from "views/AccountPage/AccountPage.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import EditCompany from "views/EditCompany/EditCompany";
import NewJob from "views/NewJob/NewJob.js";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ReactGA from "react-ga";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";

var history = createBrowserHistory();

const trackingId = "UA-67804751-4";
ReactGA.initialize(trackingId);
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    scope={config.scope}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <Switch>
        <Route path="/jobs" component={JobsPage} />
        <Route exact path="/job/:id" component={JobDetail} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <PrivateRoute exact path="/new-job" component={NewJob} />
        <PrivateRoute exact path="/account" component={AccountPage} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/edit-company" component={EditCompany} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </Router>
    <ToastContainer />
  </Auth0Provider>,
  document.getElementById("root")
);
