import React, { useEffect } from "react";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import { Editor } from "@tinymce/tinymce-react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import Axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);

export default function NewJob(props) {
  const { getTokenSilently } = useAuth0();

  const [userCompany, setUserCompany] = React.useState(true);

  const classes = useStyles();

  const [companyName, setCompanyName] = React.useState("");
  const [companyCity, setCompanyCity] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [companyDescription, setCompanyDescription] = React.useState("");
  const [companyIndustry, setCompanyIndustry] = React.useState("");
  const handleCompanyIndustryChange = event => {
    setCompanyIndustry(event.target.value);
  };

  const inputLabel = React.useRef(null);

  const [redirectToNewJob, setRedirectToNewJob] = React.useState(false);
  const renderRedirect = () => {
    if (redirectToNewJob) {
      return <Redirect to="/new-job" />;
    }
  };

  useEffect(() => {
    //If the user does not have a company, redirect them to the new company page.
    const checkForCompany = async () => {
      const token = await getTokenSilently();
      try {
        await Axios.get(
          "https://api.humboldtjobs.com/api/company/usercompany",
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        ).then(res => {
          setUserCompany(res.data);
          setCompanyCity(res.data.city);
          setCompanyName(res.data.name);
          setContactEmail(res.data.contactEmail);
          setContactPhone(res.data.contactPhone);
          setCompanyDescription(res.data.description);
          setCompanyIndustry(res.data.industry);
        });
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            setUserCompany(false);
          }
        }
      }
    };

    checkForCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {renderRedirect()}
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...props}
      />

      <Parallax small filter image={require("assets/img/sign-in.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <div
          className={classes.container}
          style={{ paddingTop: "25px", paddingBottom: "50px" }}
        >
          <Card className={classes.card}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                {userCompany === undefined ? (
                  <div>
                    <Alert
                      severity="info"
                      style={{
                        marginTop: "9px",
                        marginBottom: "9px",
                        marginLeft: "10%",
                        marginRight: "10%"
                      }}
                    >
                      COVID-19: All job postings are now free, thank you for
                      adding a job to our commuinity.
                    </Alert>
                    <Typography component="h5" variant="h5">
                      First - Create a Company Profile
                    </Typography>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <Typography component="h5" variant="h5">
                      Edit Company Profile
                    </Typography>
                  </div>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    {/* Company NAme */}
                    <div style={{ paddingTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="company-name"
                          label="Company Name"
                          variant="standard"
                          value={companyName}
                          onChange={e => setCompanyName(e.target.value)}
                        />
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    {/* Company City */}
                    <div style={{ paddingTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="company-city"
                          label="City"
                          variant="standard"
                          value={companyCity}
                          onChange={e => setCompanyCity(e.target.value)}
                        />
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* Primary Contact Email */}
                    <div style={{ paddingTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="contact-email"
                          label="Primary Contact Email"
                          variant="standard"
                          value={contactEmail}
                          onChange={e => setContactEmail(e.target.value)}
                        />
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* Primary Contact Phone */}
                    <div style={{ paddingTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          id="contact-phone"
                          label="Primary Contact Phone"
                          variant="standard"
                          value={contactPhone}
                          onChange={e => setContactPhone(e.target.value)}
                        />
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* Industry */}
                    <div style={{ paddingTop: "10px" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          ref={inputLabel}
                          id="demo-simple-select-outlined-label"
                        >
                          Industry
                        </InputLabel>
                        <Select
                          labelid="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={companyIndustry}
                          onChange={handleCompanyIndustryChange}
                        >
                          <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                          <MenuItem value={"Automotive"}>Automotive</MenuItem>
                          <MenuItem value={"Beauty"}>Beauty</MenuItem>
                          <MenuItem value={"Cannabis"}>Cannabis</MenuItem>
                          <MenuItem value={"Construction"}>
                            Construction
                          </MenuItem>
                          <MenuItem value={"Education"}>Education</MenuItem>
                          <MenuItem value={"Energy"}>Energy</MenuItem>
                          <MenuItem value={"Engineering"}>Engineering</MenuItem>
                          <MenuItem value={"Entertainment"}>
                            Entertainment
                          </MenuItem>
                          <MenuItem value={"Finance"}>Finance</MenuItem>
                          <MenuItem value={"Fishing"}>Fishing</MenuItem>
                          <MenuItem value={"Food"}>Food</MenuItem>
                          <MenuItem value={"Health Care"}>Health Care</MenuItem>
                          <MenuItem value={"Hospitality"}>Hospitality</MenuItem>
                          <MenuItem value={"Information Technology"}>
                            Information Technology
                          </MenuItem>
                          <MenuItem value={"Manufacturing"}>
                            Manufacturing
                          </MenuItem>
                          <MenuItem value={"News Media"}>News Media</MenuItem>
                          <MenuItem value={"Nonprofit"}>Nonprofit</MenuItem>
                          <MenuItem value={"Transport"}>Transport</MenuItem>
                        </Select>
                        <FormHelperText>Required</FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {/*  Company Description */}
                    <div style={{ paddingTop: "10px" }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Company Description
                      </Typography>
                      <p>
                        This description will be visible on your public company
                        listings page.
                      </p>
                      <Editor
                        apiKey="e49d5gv6wir93v2o9i10phyua7f52ig4o7v4lawwd0094j1n"
                        initialValue={
                          userCompany === undefined
                            ? "<p>We make Humboldt a better place by...</p>"
                            : companyDescription
                        }
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount"
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
                        }}
                        onChange={e => {
                          setCompanyDescription(e.target.getContent());
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        var company = {
                          Name: companyName,
                          Industry: companyIndustry,
                          Description: companyDescription,
                          City: companyCity,
                          ContactEmail: contactEmail,
                          ContactPhone: contactPhone
                        };

                        if (userCompany !== undefined) {
                          company["id"] = userCompany.id;
                        }

                        const token = await getTokenSilently();
                        console.log(company);
                        Axios.post(
                          "https://api.humboldtjobs.com/api/company",
                          company,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          }
                        ).then(res => {
                          console.log(res);
                          toast.success("Company Created!");
                          setRedirectToNewJob(true);
                        });
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}
