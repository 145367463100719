import React from "react";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import JobForm from "components/Forms/JobForm";
import Card from "components/Card/Card";
import { CardContent } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(styles);

export default function NewJob(props) {
  if (props.job) {
    console.log("Existing job");
  }

  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <script src="https://js.stripe.com/v3/"></script>
        <meta charSet="utf-8" />
        <title>Post a Job</title>
        <link rel="canonical" href="https://www.humboldtjobs.com/new-job" />
      </Helmet>
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...props}
      />
      <Parallax small filter image={require("assets/img/sign-in.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <Card>
          <CardContent>
            <Paper elevation={3}>
              <JobForm></JobForm>
            </Paper>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </div>
  );
}
