import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { NotificationsNone, Assessment, Security } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function CandidateBenefitsSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingTop: "0px" }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Reach Local Talent</h2>
          <h5 className={classes.description}>
            It is our mission to be the go to job board for candidates in
            Humboldt County. Bringing in candidates from near and far, we help
            your business fill openings. Screening questions allow you to
            identify strong applicants with ease.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Powerful dashboard"
              description="Keep track of metrics, active listings, and applicants all in one place."
              icon={Assessment}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Protection"
              description="All candidate resumes are scanned for viruses, keeping your business and computer safe"
              icon={Security}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Notifications"
              description="Stay on top of incoming applications with automated notifications and updates."
              icon={NotificationsNone}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
