export const salaryFormatter = amount => {
  if (amount === undefined) {
    return "";
  }
  let res = Math.sign(amount) * Math.abs(amount).toFixed(0) + "k";

  return `${res}`;
};

export const hourlyFormatter = amount => {
  if (amount === undefined) {
    return "";
  }
  let res = Math.sign(amount) * Math.abs(amount).toFixed(0) + "/hr";

  return `${res}`;
};

export const timeSince = date => {
  date = Date.parse(`${date}Z`);

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const employmentTypeMapper = num => {
  var res = "";
  switch (num) {
    case 0:
      res = "Full Time";
      break;
    case 1:
      res = "Part Time";
      break;
    case 2:
      res = "Contract";
      break;
    case 3:
      res = "Internship";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const payFormatter = (payType, payLow, payHigh) => {
  if (payLow === undefined || payHigh === undefined) {
    return "";
  }

  if (payLow === 0 && payHigh === 0) {
    return "DOE";
  }

  if (payType === 0) {
    let low = Math.sign(payLow) * Math.abs(payLow).toFixed(1) + "k";
    let high = Math.sign(payHigh) * Math.abs(payHigh).toFixed(1) + "k";

    return `${low} - ${high}`;
  }

  return `${payLow} - ${payHigh}/hr`;
};
