import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import lunr from "lunr";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import axios from "axios";
import {
  employmentTypeMapper,
  timeSince,
  payFormatter
} from "../../utilities/JobUtilities";

const useStyles = makeStyles(styles);

export default function JobsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [industryFilter, setIndustryFilter] = React.useState("All");

  const handleIndustryChange = event => {
    setIndustryFilter(event.target.value);
  };

  const [categoryFilter, setCategoryFilter] = React.useState("All");
  const handleCategoryChange = event => {
    setCategoryFilter(event.target.value);
  };

  const [cityFilter, setCityFilter] = React.useState("All");
  const handleCityChange = event => {
    setCityFilter(event.target.value);
  };

  const [jobTypeFilter, setJobTypeFilter] = React.useState("All");
  const handlejobTypeChange = async event => {
    setJobTypeFilter(event.target.value);
  };

  const applyDropdownFilters = () => {
    let filterResults = jobs.filter(job => {
      if (job.company.industry !== industryFilter && industryFilter !== "All") {
        return false;
      }

      if (job.company.city !== cityFilter && cityFilter !== "All") {
        return false;
      }

      if (job.employmentType !== jobTypeFilter && jobTypeFilter !== "All") {
        return false;
      }

      if (job.category !== categoryFilter && categoryFilter !== "All") {
        return false;
      }

      return true;
    });

    setFilteredJobs(filterResults);
  };

  useEffect(applyDropdownFilters, [
    industryFilter,
    cityFilter,
    jobTypeFilter,
    categoryFilter
  ]);

  const getUniqueIndustries = () => {
    if (jobs && jobs.length > 0) {
      const unique = [...new Set(jobs.map(job => job.company.industry))];
      return unique;
    }
    return [];
  };

  const getUniqueCategories = () => {
    if (jobs && jobs.length > 0) {
      const unique = [...new Set(jobs.map(job => job.category))];
      return unique;
    }
    return [];
  };

  const getUniqueCities = () => {
    if (jobs && jobs.length > 0) {
      const unique = [...new Set(jobs.map(job => job.company.city))];
      return unique;
    }
    return [];
  };
  const [index, setIndex] = React.useState();
  const [search, setSearch] = React.useState("");

  const handleSearchChange = event => {
    const filter = event.target.value;
    const results = index
      .search(filter) // search for the filter in the index
      .map(ref => ({
        ref,
        job: jobs.find(m => m.id.toString() === ref.ref)
      }))
      .map(match => match.job); // attach each item

    if (results && results.length > 0) {
      setFilteredJobs(results);
    }

    setSearch(filter);
  };

  //Get the jobs from the API
  useEffect(() => {
    const fetchData = async () => {
      await axios("https://api.humboldtjobs.com/api/jobs")
        .then(result => {
          setJobs(result.data);
          setFilteredJobs(result.data);

          setIndex(
            lunr(function() {
              this.field("title");
              this.field("description");
              this.field("company");
              this.ref("id");

              if (result.data) {
                result.data.forEach(job =>
                  this.add({
                    title: job.title,
                    id: job.id,
                    description: job.description,
                    company: job.company.name
                  })
                );
              }
            })
          );
        })
        .catch(() =>
          toast.error("Error loading jobs, please try again later.")
        );
      //console.log(result);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Jobs</title>
        <link rel="canonical" href="https://www.humboldtjobs.com/jobs" />
      </Helmet>
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/jobs-bg.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <div style={{ marginLeft: "5px", marginRight: "5px" }}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <h2 style={{ padding: "10px" }}>Jobs</h2>
            </GridContainer>
            <GridContainer justify="center" style={{ margin: "5px" }}>
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                onChange={e => handleSearchChange(e)}
                value={search}
                style={{ backgroundColor: "white", minWidth: "50%" }}
              />
            </GridContainer>
            <GridContainer style={{ marginBottom: "30px" }} justify="center">
              <Card style={{ width: "100%", padding: "7px" }}>
                <GridContainer
                  style={{ marginBottom: "10px" }}
                  justify="center"
                >
                  <GridItem sm={3} xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Job Category
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={categoryFilter}
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value={"All"} key={"filter-Industry-All"}>
                          All
                        </MenuItem>
                        {getUniqueCategories().map(j => (
                          <MenuItem value={j} key={`filter-${j}`}>
                            {j}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem sm={3} xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Job Type
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={jobTypeFilter}
                        onChange={handlejobTypeChange}
                      >
                        <MenuItem value={"All"} key={"filter-City-All"}>
                          All
                        </MenuItem>
                        <MenuItem value={0}>Full Time</MenuItem>
                        <MenuItem value={1}>Part Time</MenuItem>
                        <MenuItem value={2}>Contract</MenuItem>
                        <MenuItem value={3}>Internship</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem sm={3} xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Company Industry
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={industryFilter}
                        onChange={handleIndustryChange}
                      >
                        <MenuItem value={"All"} key={"filter-Industry-All"}>
                          All
                        </MenuItem>
                        {getUniqueIndustries().map(j => (
                          <MenuItem value={j} key={`filter-${j}`}>
                            {j}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem sm={3} xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={cityFilter}
                        onChange={handleCityChange}
                      >
                        <MenuItem value={"All"} key={"filter-City-All"}>
                          All
                        </MenuItem>
                        {getUniqueCities().map(c => (
                          <MenuItem value={c} key={`filter-${c}`}>
                            {c}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridContainer>
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              {filteredJobs.map(j => {
                return (
                  <GridItem key={j.id}>
                    <Link to={`/job/${j.id}`} className={classes.dropdownLink}>
                      <Card style={{ width: "100%" }}>
                        <CardBody>
                          <GridContainer direction="row" spacing={3}>
                            <GridItem sm={10} xs={8}>
                              <p>{j.company.name}</p>
                              <h4
                                className={classes.cardTitle}
                                style={{ display: "inline" }}
                              >
                                {j.title}
                              </h4>
                              <p>
                                {employmentTypeMapper(j.employmentType)} /{" "}
                                {j.company.city}
                              </p>
                            </GridItem>
                            <GridItem sm={2} xs={4}>
                              <p>
                                {payFormatter(j.payType, j.payLow, j.payHigh)}
                              </p>
                              <p>{timeSince(j.postedDate)} ago</p>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </Link>
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
