import React, { useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const columns = [
  { id: "id", label: "Application Id", minWidth: 20 },
  {
    id: "user",
    label: "Applicant",
    minWidth: 35,
    format: value => `${value.firstName} ${value.lastName}`
  },
  {
    id: "createDate",
    label: "Date submitted",
    minWidth: 20,
    format: date => new Date(date).toLocaleDateString()
  }
];

export default function ApplicationDisplay(props) {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };

  const [page, setPage] = React.useState(0);
  const [appsPerPage, setAppsPerPage] = React.useState(10);

  const [apps, setApps] = React.useState([]);
  const [quiz, setQuiz] = React.useState(undefined);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeAppsPerPage = event => {
    setAppsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = await getTokenSilently();
      await axios
        .get(
          `https://api.humboldtjobs.com/api/jobs/${props.job.id}/applications`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(result => {
          setApps(result.data);
        })
        .catch(() =>
          toast.error("Error loading applications, please try again later.")
        );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3>Applicants - {props.job.title}</h3>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell>Résumé</TableCell>
              <TableCell>Screening Questions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps
              .slice(page * appsPerPage, page * appsPerPage + appsPerPage)
              .map(app => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={app.id}>
                    {columns.map(column => {
                      const value = app[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={async () => {
                          const token = await getTokenSilently();
                          await axios
                            .get(
                              `https://api.humboldtjobs.com/api/jobs/application/${app.id}/resume`,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`
                                },
                                responseType: "blob" //important
                              }
                            )
                            .then(({ data }) => {
                              const downloadUrl = window.URL.createObjectURL(
                                new Blob([data])
                              );
                              const link = document.createElement("a");
                              link.href = downloadUrl;
                              link.setAttribute("download", app.resumeLocation); //any other extension
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                            })
                            .catch(() =>
                              toast.error(
                                "Error downloading resume, please try again later."
                              )
                            );
                        }}
                      >
                        Résumé
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          const token = await getTokenSilently();
                          await axios
                            .get(
                              `https://api.humboldtjobs.com/api/quiz/job/${props.job.id}/${app.userId}`,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`
                                }
                              }
                            )
                            .then(({ data }) => {
                              setQuiz(data);
                              setModalOpen(true);
                            })
                            .catch(() =>
                              toast.error(
                                "Error fetching  screening questions, please try again later."
                              )
                            );
                        }}
                      >
                        Questions
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={apps.length}
        rowsPerPage={appsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeAppsPerPage}
      />
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          {quiz !== undefined
            ? quiz.questions.map(q => (
                <div>
                  <p>
                    <b>{q.title}</b>
                  </p>
                  <ul>
                    {q.choices.map(c => (
                      <li>{c.choiceText}</li>
                    ))}
                  </ul>
                </div>
              ))
            : ""}
        </DialogContent>
      </Dialog>
    </div>
  );
}
