import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "@material-ui/core/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TextField from "@material-ui/core/TextField";

import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useAuth0 } from "../../react-auth0-spa";
import { toast } from "react-toastify";

import Parser from "html-react-parser";
import Axios from "axios";
import { payFormatter } from "../../utilities/JobUtilities";
import { Helmet } from "react-helmet";
import Rollbar from "rollbar";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";

const useStyles = makeStyles(styles);

export default function JobDetail(props) {
  const {
    getTokenSilently,
    isAuthenticated,
    loginWithPopup,
    user
  } = useAuth0();
  const classes = useStyles();
  const { ...rest } = props;

  let { id } = useParams();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState();

  const shareUrl = window.location.href;

  const rollbar = new Rollbar({
    accessToken: "dc68b6ba810648e3bcc9c3479d10c492",
    captureUncaught: false,
    captureUnhandledRejections: false,
    payload: {
      environment: "production"
    }
  });

  if (isAuthenticated && user) {
    rollbar.configure({
      payload: {
        person: {
          id: user.sub,
          email: user.email
        }
      }
    });
  }

  const handleClickOpen = async () => {
    if (!isAuthenticated) {
      await loginWithPopup();
    }
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleResumeChange = event => {
    let file = event.target.files[0];
    console.log(file);

    if (file) {
      let ext = file.name
        .split(".")
        .pop()
        .toLowerCase();

      if (file.size > 10000000) {
        console.log("file too large");
        toast.error("File too large - 10MB max.");
        return;
      }

      if (ext === "pdf" || ext === "docx" || ext === "doc ") {
        setSelectedFile(file);
      } else {
        toast.error("File must be PDF or Word Document");
      }
    }
  };

  const handleApply = async () => {
    let quizAnswers = [...answers];

    quiz.questions.forEach(question => {
      question.choices.forEach(choice => {
        if (choice.checked) {
          quizAnswers.push({
            Id: -1,
            UserId: -1,
            QuestionId: question.id,
            Value: choice.choiceText
          });
        }
      });
    });

    const data = new FormData();
    data.append("File", selectedFile);
    data.append("JobId", id);

    const token = await getTokenSilently();

    //Submit the job application
    await Axios.post("https://api.humboldtjobs.com/api/jobs/apply", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    })
      .then(async res => {
        //Submit the quiz answers if the application sticks
        let answerData = { Answers: quizAnswers };
        await Axios.post(
          "https://api.humboldtjobs.com/api/quiz/answer",
          answerData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        ).catch(error => {
          console.log(error);
          rollbar.error(
            "Error submitting application questions client side",
            error
          );
        });

        if (res.data && res.data.includes("Error")) {
          toast.error(res.data);
        } else {
          toast.success("Application Submitted!");
        }
      })
      .catch(error => {
        console.log(error);
        rollbar.error("Error submitting application client side", error);
        toast.error("Server error, please try again later.");
      });

    setModalOpen(false);
  };

  const [job, setJob] = React.useState({
    id: id,
    companyId: 0,
    company: {
      id: 0,
      name: "",
      industry: "",
      description: "",
      city: "",
      state: null,
      street: null,
      zip: null,
      contactEmail: "",
      contactPhone: "",
      logoUrl: null,
      postCredts: 0
    },
    title: "",
    description: "",
    employmentType: -1,
    payType: -1,
    payLow: -1,
    payHigh: -1,
    postedDate: "",
    active: true,
    imageUrls: null,
    overrideLink: ""
  });

  const [quiz, setQuiz] = React.useState(undefined);
  const [answers, setAnswers] = React.useState([]);

  const handleAnswerChange = choice => {
    let newQuiz = {
      id: quiz.id,
      jobId: quiz.jobId,
      questions: quiz.questions.map(q => ({
        id: q.id,
        title: q.title,
        choices: q.choices.map(c => {
          if (
            c.questionId === choice.questionId &&
            c.choiceText === choice.choiceText
          ) {
            return {
              questionId: c.questionId,
              choiceText: c.choiceText,
              checked: !c.checked
            };
          }
          return {
            questionId: c.questionId,
            choiceText: c.choiceText,
            checked: c.checked
          };
        })
      }))
    };

    setQuiz(newQuiz);
  };

  useEffect(() => {
    const getJobDetail = async () => {
      try {
        const result = await Axios.get(
          `https://api.humboldtjobs.com/api/jobs/${id}`
        );
        setJob(result.data);
      } catch (err) {
        toast.error("Error loading job detail, please try again later.");
      }
    };

    const getJobQuiz = async () => {
      try {
        const result = await Axios.get(
          `https://api.humboldtjobs.com/api/quiz/job/${id}`
        );

        let newQuiz = {
          id: result.data.id,
          jobId: result.data.jobId,
          questions: result.data.questions.map(q => ({
            id: q.id,
            title: q.title,
            choices: q.choices.map(c => ({
              questionId: c.questionId,
              choiceText: c.choiceText,
              checked: false
            }))
          }))
        };

        setQuiz(newQuiz);
      } catch (err) {
        //toast.error("Error loading job quiz, please try again later.");
        console.log("no quiz");
      }
    };

    getJobDetail();
    getJobQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {job.title} | {job.company.name}
        </title>
        <link
          rel="canonical"
          href={`https://www.humboldtjobs.com/job/{${job.id}}`}
        />
      </Helmet>
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/jobs-bg.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <div>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <h2 style={{ padding: "10px" }}>
                {job.title} - {job.company.name}
              </h2>
            </GridContainer>
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <GridItem>
                <Card className={classes.card} style={{ marginBottom: "25px" }}>
                  <CardContent>
                    Share posting:
                    <p>
                      <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={32} round />{" "}
                      </LinkedinShareButton>
                      <EmailShareButton
                        url={shareUrl}
                        onClick={() => {
                          var dummy = document.createElement("input"),
                            text = window.location.href;

                          document.body.appendChild(dummy);
                          dummy.value = text;
                          dummy.select();
                          document.execCommand("copy");
                          document.body.removeChild(dummy);
                          toast.info("Link copied to clipboard");
                        }}
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </p>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      style={{ marginTop: "5px" }}
                    >
                      Job Description
                    </Typography>
                    {Parser(job.description)}
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Pay
                    </Typography>
                    <br></br>
                    <ul>
                      <li>
                        {payFormatter(job.payType, job.payLow, job.payHigh)}
                      </li>
                    </ul>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Benefits
                    </Typography>
                    <br></br>
                    <ul>{job.medical ? <li>Medical</li> : ""}</ul>
                    <ul>{job.dental ? <li>Dental</li> : ""}</ul>
                    <ul>{job.vision ? <li>Vision</li> : ""}</ul>
                    <ul>{job.retirement ? <li>Retirement</li> : ""}</ul>
                    <ul>
                      {!(
                        job.medical ||
                        job.dental ||
                        job.vision ||
                        job.retirement
                      ) ? (
                        <li>None</li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </CardContent>
                  <CardActions>
                    {job.overrideLink === "" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                      >
                        Apply
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={job.overrideLink}
                      >
                        Go To Application
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />

      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          Job Applicaion - {job.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To apply to this job please upload your resume.
          </DialogContentText>
          <Button
            variant="contained"
            component="label"
            color={selectedFile ? "default" : "primary"}
          >
            {selectedFile ? "Replace Resume" : "Upload Resume"}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleResumeChange}
            />
          </Button>
          <p>
            <b>{selectedFile ? selectedFile.name : ""}</b>
          </p>
          {quiz !== undefined ? (
            <Card
              className={classes.card}
              style={{ marginBottom: "25px", paddingTop: "0px" }}
            >
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  style={{ margin: "5px" }}
                >
                  Application Questions
                </Typography>
                <br></br>
                <p>
                  Please answer the following questions and then click "Apply"
                </p>
                {quiz !== undefined
                  ? quiz.questions.map((q, qIndex) => {
                      let choices = q.choices;
                      return (
                        <div key={qIndex}>
                          <p
                            key={q.id}
                            style={{ fontSize: "1.1em", marginTop: "1em" }}
                          >
                            <b>{q.title}</b>
                          </p>
                          {choices.length > 0 ? (
                            <FormControl
                              component="fieldset"
                              className={classes.formControl}
                            >
                              {/* <FormLabel component="legend">
                              Select answers:
                            </FormLabel> */}

                              <FormGroup>
                                {choices.map((c, cIndex) => (
                                  <FormControlLabel
                                    key={cIndex}
                                    control={
                                      <Checkbox
                                        checked={
                                          quiz.questions[qIndex].choices[cIndex]
                                            .checked
                                        }
                                        onChange={() => handleAnswerChange(c)}
                                        value={c.choiceText}
                                      />
                                    }
                                    label={c.choiceText}
                                  />
                                ))}
                              </FormGroup>
                            </FormControl>
                          ) : (
                            <TextField
                              id="outlined-multiline-static"
                              label="Response"
                              multiline
                              rows="5"
                              defaultValue=""
                              variant="outlined"
                              fullWidth
                              onChange={e => {
                                let text = e.target.value;
                                let newAns = Array.from(answers);
                                newAns = newAns.filter(
                                  a => a.questionId !== q.id
                                );
                                newAns.push({ questionId: q.id, value: text });
                                setAnswers(newAns);
                              }}
                            />
                          )}
                        </div>
                      );
                    })
                  : ""}
              </CardContent>
            </Card>
          ) : (
            undefined
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            color="primary"
            disabled={selectedFile ? false : true}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
