import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Redirect } from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { employmentTypeMapper } from "../../utilities/JobUtilities";
import { useAuth0 } from "../../react-auth0-spa";
import axios from "axios";
import { toast } from "react-toastify";
import JobForm from "components/Forms/JobForm";
import ApplicationDisplay from "components/ApplicationDisplay/ApplicationDisplay";

const columns = [
  { id: "id", label: "Job Id", minWidth: 20 },
  { id: "title", label: "Job Title", minWidth: 35 },
  {
    id: "employmentType",
    label: "Job Type",
    minWidth: 25,
    format: value => employmentTypeMapper(value)
  },
  {
    id: "views",
    label: "Listing Views",
    minWidth: 5
  },
  {
    id: "applicants",
    label: "Applicants",
    minWidth: 5
  },
  {
    id: "postedDate",
    label: "Posted Date",
    minWidth: 20,
    format: date => new Date(date).toLocaleDateString()
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export default function Dashboard(props) {
  const classes = useStyles();
  const { loading, user, getTokenSilently } = useAuth0();
  const [page, setPage] = React.useState(0);
  const [jobsPerPage, setJobsPerPage] = React.useState(10);
  const [redirectToEditCompany, setRedirectToEditCompany] = React.useState(
    false
  );
  const [jobs, setJobs] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState({});
  const [dialogStatus, setDialogStatus] = React.useState("job-form");
  const [userCompany, setUserCompany] = React.useState(undefined);

  const renderRedirect = () => {
    if (redirectToEditCompany) {
      return <Redirect to="/edit-company" />;
    }
  };

  useEffect(() => {
    const checkForCompany = async () => {
      const token = await getTokenSilently();

      await axios
        .get("https://api.humboldtjobs.com/api/company/usercompany", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(async ({ data }) => {
          setUserCompany(data);

          await axios
            .get("https://api.humboldtjobs.com/api/jobs/userjobs", {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            .then(result => {
              setJobs(result.data);
            })
            .catch(() =>
              toast.error("Error loading jobs, please try again later.")
            );
        })
        .catch(async () => {
          await axios
            .get("https://api.humboldtjobs.com/api/jobs/appliedjobs", {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            .then(result => {
              setJobs(result.data);
            })
            .catch(() =>
              toast.error("Error loading jobs, please try again later.")
            );
        });
    };

    checkForCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeJobsPerPage = event => {
    setJobsPerPage(+event.target.value);
    setPage(0);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleEditOpen = async job => {
    setDialogStatus("job-form");
    setSelectedJob(job);

    setModalOpen(true);
  };

  const handleApplicationsOpen = async job => {
    setDialogStatus("applications");
    setSelectedJob(job);

    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {renderRedirect()}
      <Header
        color="white"
        brand="Humboldt County Jobs"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />
      <Parallax small filter image={require("assets/img/jobs-bg.jpg")} />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "90vh", backgroundColor: "#f8f8ff" }}
      >
        <div
          style={{ maxWidth: "100%", paddingLeft: "5%", paddingRight: "5%" }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <h2 style={{ padding: "10px" }}>Dashboard</h2>
            </GridContainer>

            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <GridItem>
                {userCompany !== undefined ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setRedirectToEditCompany(true)}
                  >
                    Edit Company Profile
                  </Button>
                ) : (
                  ""
                )}
              </GridItem>
              <GridItem>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {userCompany !== undefined
                        ? "Job Listings"
                        : "Applications"}
                    </Typography>
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map(column => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            {userCompany !== undefined ? (
                              <TableCell>Share</TableCell>
                            ) : (
                              ""
                            )}
                            {userCompany !== undefined ? (
                              <TableCell>Edit</TableCell>
                            ) : (
                              undefined
                            )}
                            {userCompany !== undefined ? (
                              <TableCell>View Applications</TableCell>
                            ) : (
                              <TableCell>View Listing</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {jobs
                            .slice(
                              page * jobsPerPage,
                              page * jobsPerPage + jobsPerPage
                            )
                            .map(job => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={job.id}
                                >
                                  {columns.map(column => {
                                    const value = job[column.id];
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {column.format
                                          ? column.format(value)
                                          : value}
                                      </TableCell>
                                    );
                                  })}
                                  {userCompany !== undefined ? (
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          var dummy = document.createElement(
                                              "input"
                                            ),
                                            text = `${window.location.origin}/job/${job.id}`;

                                          document.body.appendChild(dummy);
                                          dummy.value = text;
                                          dummy.select();
                                          document.execCommand("copy");
                                          document.body.removeChild(dummy);
                                          toast.info(
                                            "Link copied to clipboard"
                                          );
                                        }}
                                      >
                                        Copy Link
                                      </Button>
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {userCompany !== undefined ? (
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          handleEditOpen(job);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </TableCell>
                                  ) : (
                                    undefined
                                  )}
                                  {userCompany !== undefined ? (
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleApplicationsOpen(job)
                                        }
                                      >
                                        View Applications
                                      </Button>
                                    </TableCell>
                                  ) : (
                                    <TableCell>
                                      <Button
                                        target="_blank"
                                        href={`/job/${job.id}`}
                                        variant="contained"
                                        color="primary"
                                      >
                                        View Listing
                                      </Button>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={jobs.length}
                      rowsPerPage={jobsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeJobsPerPage}
                    />
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogContent>
            {dialogStatus === "job-form" ? (
              <JobForm
                job={selectedJob}
                closeDialog={() => handleClose()}
              ></JobForm>
            ) : (
              <ApplicationDisplay job={selectedJob}></ApplicationDisplay>
            )}
          </DialogContent>
        </Dialog>
      </div>
      <Footer />
    </div>
  );
}
