/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import { Queue, AccountBox, LockOpen } from "@material-ui/icons";

// core components
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles(styles);

const HeaderLinks = () => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/new-job"
          rel="noopener noreferrer"
          color="transparent"
          className={classes.navLink}
        >
          <Queue className={classes.icons} /> Post a job
        </Button>
      </ListItem>
      {isAuthenticated && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Account"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={AccountBox}
            dropdownList={[
              <Link to="/dashboard" className={classes.dropdownLink}>
                Dashboard
              </Link>,
              // <Link to="/account" className={classes.dropdownLink}>
              //   Account
              // </Link>,
              <a
                target="_blank"
                className={classes.dropdownLink}
                onClick={() => logout()}
              >
                Logout
              </a>
            ]}
          />
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        {!isAuthenticated && (
          <Button color="transparent" onClick={() => loginWithRedirect({})}>
            <LockOpen className={classes.icons} /> Log in
          </Button>
        )}
      </ListItem>
    </List>
  );
};

export default HeaderLinks;
