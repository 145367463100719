import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/jobsPage.js";
import Axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import { salaryFormatter, hourlyFormatter } from "../../utilities/JobUtilities";
import { toast } from "react-toastify";
import Chip from "@material-ui/core/Chip";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);

function payRangeText(value) {
  return `$${salaryFormatter(value)}`;
}

export default function JobForm(props) {
  const { getTokenSilently } = useAuth0();

  const title = props.job ? "Edit Job" : "New Job Posting";
  const saveText = props.job ? "Save" : "Submit Job";

  const [userCompany, setUserCompany] = React.useState({ name: "" });
  const [redirectToNewCompany, setRedirectToNewCompany] = React.useState(false);
  const [jobId, setJobId] = React.useState(-1);

  //If the user does not have a company, redirect them to the new company page.
  useEffect(() => {
    const checkForCompany = async () => {
      const token = await getTokenSilently();
      try {
        const result = await Axios.get(
          "https://api.humboldtjobs.com/api/company/usercompany",
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setUserCompany(result.data);
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            setRedirectToNewCompany(true);
          }
        }
      }
    };

    checkForCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [jobTitle, setJobTitle] = React.useState("");
  const [jobDescription, setJobDescription] = React.useState("");

  const [payRange, setPayRange] = React.useState([20, 85]);
  const handlePayRangeChange = (event, newValue) => {
    setPayRange(newValue);
  };

  const handleDoEToggle = () => {
    if (payRange[0] === 0 && payRange[1] === 0) {
      setPayRange([20, 85]);
    } else {
      setPayRange([0, 0]);
    }
  };

  const [jobType, setJobType] = React.useState("");
  const handleJobTypeChange = event => {
    setJobType(event.target.value);
  };

  const [jobCategory, setJobCategory] = React.useState("");
  const handleJobCategoryChange = event => {
    setJobCategory(event.target.value);
  };

  const [payType, setPayType] = React.useState(0);
  const handlePayTypeChange = event => {
    setPayType(event.target.value);
  };

  const [overrideLink, setOverrideLink] = React.useState("");

  const [benefits, setBenefits] = React.useState({
    medical: false,
    dental: false,
    vision: false,
    retirement: false
  });

  const handleBenefitChange = name => event => {
    setBenefits({ ...benefits, [name]: event.target.checked });
  };

  const { medical, dental, vision, retirement } = benefits;

  const renderRedirect = () => {
    if (redirectToNewCompany) {
      toast.info(
        "Welcome! Please create a company profile prior to posting a job."
      );
      return <Redirect to="/edit-company" />;
    } else if (jobId !== -1) {
      toast.success("Job submitted!.");
      return <Redirect to={`/job/${jobId}`} />;
    }
  };

  const handleNewJobSubmit = async job => {
    const token = await getTokenSilently();
    Axios.post("https://api.humboldtjobs.com/api/jobs", job, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      let newJobId = res.data;

      // /* global Stripe */
      // var stripe = Stripe("pk_live_jKGHvy5QKv7iPkBNRz4A3zWp");

      // //Get a session Id
      // let sessionId = "";

      // Axios.get(`https://api.humboldtjobs.com/api/stripe/${newJobId}`).then(
      //   res => {
      //     console.log(res);
      //     sessionId = res.data;

      //     stripe
      //       .redirectToCheckout({
      //         // Make the id field from the Checkout Session creation API response
      //         // available to this file, so you can provide it as parameter here
      //         // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      //         sessionId: sessionId
      //       })
      //       .then(function(result) {
      //         // If `redirectToCheckout` fails due to a browser or network
      //         // error, display the localized error message to your customer
      //         // using `result.error.message`.
      //         console.log(result);
      //       });
      //   }
      // );

      setJobId(newJobId);
    });
  };

  const handleExistingJobUpdate = async job => {
    const token = await getTokenSilently();
    Axios.put(`https://api.humboldtjobs.com/api/jobs/${props.job.id}`, job, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(() => {
        toast.success("Job Updated.");
        if (props.closeDialog) {
          props.closeDialog();
        }
      })
      .catch(e => {
        toast.error("Error saving job, please try again later.");
        console.log(e, job);
      });
  };

  useEffect(() => {
    if (props.job) {
      let { job } = props;
      setJobTitle(job.title);
      setJobCategory(job.category);
      setJobType(job.employmentType);
      setPayType(job.payType);
      setPayRange([job.payLow, job.payHigh]);
      setBenefits({
        medical: job.medical,
        dental: job.dental,
        vision: job.vision,
        retirement: job.retirement
      });
      setJobDescription(job.description);
      setUserCompany(job.company);
      setOverrideLink(job.overrideLink);
    }
  }, [props]);

  const [quiz, setQuiz] = React.useState({
    questions: []
  });
  const [questionType, setQuestionType] = React.useState("");
  const [questionText, setQuestionText] = React.useState("");
  const [choices, setChoices] = React.useState([]);
  const [choiceText, setChoiceText] = React.useState("");

  const canSubmitJob = () => {
    let result = true;

    if (jobTitle === "" || jobTitle === undefined) {
      result = false;
    }

    if (jobType === "" || jobType === undefined) {
      result = false;
    }

    if (jobDescription === "" || jobDescription === undefined) {
      result = false;
    }

    return result;
  };

  const canAddQuestion = () => {
    let result = true;
    if (questionType === "" || questionType === undefined) {
      result = false;
    }

    if (questionText === "" || questionText === undefined) {
      result = false;
    }

    if (questionType === "MC" && choices.length < 1) {
      result = false;
    }

    return result;
  };

  const addQuestion = question => {
    let newQuiz = quiz;
    newQuiz.questions = [question, ...quiz.questions];
    setQuiz(newQuiz);

    setQuestionText("");
    setQuestionType("");
    setChoices([]);
    toast.info("Question added.");
  };

  const addChoice = choice => {
    setChoices(Array.from(new Set([choice, ...choices])));
    setChoiceText("");
    console.log(choices);
  };

  const deleteChoice = choice => {
    let arr = choices.filter(item => item !== choice);
    setChoices(Array.from(new Set([...arr])));
  };

  const deleteQuestion = title => {
    let arr = quiz.questions.filter(question => question.title !== title);
    let newQuiz = {};
    newQuiz.questions = Array.from(arr);
    setQuiz(newQuiz);
  };

  const handlequestionTypeChange = qt => {
    setChoices([]);
    setQuestionType(qt);
    if (qt === "YN") {
      setChoices(["Yes", "No"]);
    }
  };

  const addActiveQuestion = () => {
    addQuestion({
      title: questionText,
      choices: choices.map(ch => {
        return {
          choiceText: ch
        };
      })
    });
  };

  const handleSave = async () => {
    if (canSubmitJob() && canAddQuestion()) {
      addActiveQuestion();
    }

    let job = {
      title: jobTitle,
      category: jobCategory,
      employmentType: jobType,
      payType,
      payLow: payRange[0],
      payHigh: payRange[1],
      medical: benefits.medical,
      dental: benefits.dental,
      vision: benefits.vision,
      retirement: benefits.retirement,
      description: jobDescription,
      company: userCompany,
      overrideLink: overrideLink
    };

    console.log(job);

    if (props.job !== undefined) {
      await handleExistingJobUpdate(job);
    } else {
      job.quiz = {
        questions: quiz.questions
      };
      await handleNewJobSubmit(job);
    }
  };
  return (
    <div>
      {renderRedirect()}
      <div
        className={classes.container}
        style={{ paddingTop: "25px", paddingBottom: "50px" }}
      >
        <div className={classes.details}>
          <Typography component="h4" variant="h4">
            {title}
          </Typography>

          {props.job === undefined ? (
            <Alert
              severity="info"
              style={{
                marginTop: "9px",
                marginBottom: "9px",
                marginLeft: "10%",
                marginRight: "10%"
              }}
            >
              COVID-19: All job postings are now free, thank you for adding a
              job to our commuinity.
            </Alert>
          ) : (
            ""
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              {/* Job Titile */}
              <div style={{ paddingTop: "10px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="standard-basic"
                    label="Job Title"
                    variant="outlined"
                    value={jobTitle}
                    onChange={e => setJobTitle(e.target.value)}
                  />
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* Job Type */}
              <div style={{ paddingTop: "10px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="job-type-label">Job Type</InputLabel>
                  <Select
                    labelId="job-type-label"
                    labelWidth={labelWidth}
                    id="demo-simple-select-outlined"
                    value={jobType}
                    onChange={handleJobTypeChange}
                    style={{ minWidth: "200px" }}
                  >
                    <MenuItem value={0}>Full Time</MenuItem>
                    <MenuItem value={1}>Part Time</MenuItem>
                    <MenuItem value={2}>Contract</MenuItem>
                    <MenuItem value={3}>Internship</MenuItem>
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* Job Category */}
              <div style={{ paddingTop: "10px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="job-category-label">Job Category</InputLabel>
                  <Select
                    labelId="job-category-label"
                    labelWidth={labelWidth}
                    id="demo-simple-select-outlined"
                    value={jobCategory}
                    onChange={handleJobCategoryChange}
                    style={{ minWidth: "200px" }}
                  >
                    <MenuItem value={"Accounting / Finance"}>
                      Accounting / Finance
                    </MenuItem>
                    <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                    <MenuItem value={"Bissiness / Managemnet"}>
                      Bissiness / Managemnet
                    </MenuItem>
                    <MenuItem value={"Cannabis"}>Cannabis</MenuItem>
                    <MenuItem value={"Construction"}>Construction</MenuItem>
                    <MenuItem value={"Design"}>Design</MenuItem>
                    <MenuItem value={"Education"}>Education</MenuItem>
                    <MenuItem value={"Engineering"}>Engineering</MenuItem>
                    <MenuItem value={"Food / Hospitality"}>
                      Food / Hospitality
                    </MenuItem>
                    <MenuItem value={"General Labor"}>General Labor</MenuItem>
                    <MenuItem value={"Government"}>Government</MenuItem>
                    <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                    <MenuItem value={"Human Resources"}>
                      Human Resources
                    </MenuItem>
                    <MenuItem value={"Information Technology"}>
                      Information Technology
                    </MenuItem>
                    <MenuItem value={"Legal"}>Legal</MenuItem>
                    <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                    <MenuItem value={"Marketing"}>Marketing</MenuItem>
                    <MenuItem value={"News / Media"}>News / Media</MenuItem>
                    <MenuItem value={"Office"}>Office</MenuItem>
                    <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
                    <MenuItem value={"Retail"}>Retail</MenuItem>
                    <MenuItem value={"Sales"}>Sales</MenuItem>
                    <MenuItem value={"Transportation"}>Transportation</MenuItem>
                  </Select>
                  <FormHelperText>Required</FormHelperText>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* Pay Type */}
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="pay-type-label">
                  Pay Type
                </InputLabel>
                <Select
                  labelid="pay-type-label"
                  labelWidth={65}
                  id="demo-simple-select-outlined"
                  value={payType}
                  onChange={handlePayTypeChange}
                  style={{ minWidth: "200px" }}
                >
                  <MenuItem value={0}>Salary</MenuItem>
                  <MenuItem value={1}>Hourly</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* Pay Range */}
              <div style={{ paddingTop: "10px" }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Pay Range
                </Typography>
                <Slider
                  min={15}
                  max={150}
                  disabled={payRange[0] === 0 && payRange[1] === 0}
                  value={payRange}
                  onChange={(e, nv) => handlePayRangeChange(e, nv)}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={payRangeText}
                  valueLabelFormat={v => {
                    if (payType === 0) {
                      return salaryFormatter(v);
                    } else if (payType === 1) {
                      return hourlyFormatter(v);
                    }
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={payRange[0] === 0 && payRange[1] === 0}
                      onChange={handleDoEToggle}
                      value=""
                    />
                  }
                  label="Depends On Experience"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              {/* Benefits */}
              <FormControl variant="outlined" className={classes.formControl}>
                <FormLabel component="legend">Benefits</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={medical}
                        onChange={handleBenefitChange("medical")}
                        value="medical"
                      />
                    }
                    label="Medical"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dental}
                        onChange={handleBenefitChange("dental")}
                        value="dental"
                      />
                    }
                    label="Dental"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vision}
                        onChange={handleBenefitChange("vision")}
                        value="vision"
                      />
                    }
                    label="Vision"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retirement}
                        onChange={handleBenefitChange("retirement")}
                        value="retirement"
                      />
                    }
                    label="Retirement"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {/*  Job Description */}
              <div style={{ paddingTop: "10px" }}>
                <Typography variant="subtitle1" color="textSecondary">
                  Job Description
                </Typography>
                <Editor
                  apiKey="e49d5gv6wir93v2o9i10phyua7f52ig4o7v4lawwd0094j1n"
                  initialValue={
                    props.job
                      ? props.job.description
                      : "<p>Fill in job description.</p>"
                  }
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | paste",
                    contextmenu:
                      "paste | link image inserttable | cell row column deletetable"
                  }}
                  onChange={e => {
                    setJobDescription(e.target.getContent());
                  }}
                />
              </div>
            </Grid>
            {props.job === undefined ? (
              <Grid item xs={12}>
                {/* Screening Questions */}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Screening Questions: {quiz.questions.length}
                    </Typography>
                    <div>
                      {quiz.questions.map(q => (
                        <div key={q.title}>
                          <Chip
                            label={q.title}
                            onDelete={() => deleteQuestion(q.title)}
                            style={{ marginLeft: "5px", marginBottom: "5px" }}
                          ></Chip>
                        </div>
                      ))}
                    </div>
                    <p style={{ marginBottom: "1px" }}>
                      Add as many screening questions as you like. However, be
                      sure your questions adhere to{" "}
                      <a
                        href="https://www.dfeh.ca.gov/employment-2/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        DFEH guidelines
                      </a>
                      .
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="question-text"
                      label="Question Text"
                      variant="outlined"
                      fullWidth
                      value={questionText}
                      onChange={e => setQuestionText(e.target.value)}
                      helperText="Ex: Do you have experience with Microsoft Excel?"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel ref={inputLabel} id="question-type-label">
                        Question Type
                      </InputLabel>
                      <Select
                        labelId="question-type-label"
                        labelWidth={labelWidth}
                        id="demo-simple-select-outlined"
                        value={questionType}
                        onChange={e => handlequestionTypeChange(e.target.value)}
                        style={{ minWidth: "200px" }}
                      >
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"YN"}>Yes / No</MenuItem>
                        <MenuItem value={"MC"}>Multiple Choice</MenuItem>
                        <MenuItem value={"UI"}>Short Answer</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      style={{ marginTop: "12px", marginLeft: "10px" }}
                      disabled={!canAddQuestion()}
                      onClick={addActiveQuestion}
                    >
                      Add Question
                    </Button>
                  </Grid>
                  {questionType === "MC" ? (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Add Choices
                      </Typography>
                      {choices.length > 0 ? (
                        <div>
                          Choices:{" "}
                          {choices.map(c => (
                            <Chip
                              key={`choice-${c}`}
                              label={c}
                              onDelete={() => deleteChoice(c)}
                              style={{ marginLeft: "5px", marginBottom: "5px" }}
                            />
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      <TextField
                        id="outlined-basic"
                        label="Choice Text"
                        variant="outlined"
                        style={{ marginRight: "10px" }}
                        value={choiceText}
                        onChange={e => setChoiceText(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        style={{ marginTop: "12px" }}
                        size="small"
                        onClick={e => addChoice(choiceText)}
                      >
                        Add Choice
                      </Button>
                      <p>
                        Users will be able to select more than one choice, If
                        you want them to only chose one please specify "(chose
                        one)" in the question text.
                      </p>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1" color="textSecondary">
                Application redirect (Optional)
              </Typography>
              {/* Override Link */}
              <div style={{ paddingTop: "10px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <p>
                    <b>OPTIONAL:</b> If you place a link in this field, when the
                    user clicks "Apply" they will be{" "}
                    <b>redirectred to the link entered.</b> This is used by
                    companies who want applicants to apply at their ATS.
                  </p>
                  <TextField
                    id="apply-override"
                    label="Application Redirect Link"
                    variant="outlined"
                    value={overrideLink}
                    onChange={e => setOverrideLink(e.target.value)}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <br></br>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!canSubmitJob()}
              >
                {saveText}
              </Button>
              {props.job ? (
                <p>
                  Upon submit you wll be redirected to checkout. You can always
                  edit this listing via the dashboard after payment
                </p>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
